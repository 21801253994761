// import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

export default function PostPayment() {
	// const [response, setResponse] = useState(null);
	// const [isLoading, setLoading] = useState(true);
	// const [transactionId, setTransactionId] = useState(null);
	// const [orderId, setOrderId] = useState(null);
	// const [responseText, setResponseText] = useState(null);
	// const [additionalText, setAdditionalText] = useState("");

	// useEffect(() => {
	// const urlParams = new URLSearchParams(window.location.search);
	// const responseCode = urlParams.get("resposneCode");
	// const description = urlParams.get("description");
	// const order = urlParams.get("orderID");
	// const transaction = urlParams.get("paymentID");
	// if (responseCode === "01") {
	// 	setAdditionalText("Попробуйте оформить заказ заново.");
	// }
	// if (responseCode === "02003") {
	// 	setAdditionalText(
	// 		<>
	// 			Мы принимаем к оплате только карты МИР.
	// 			<br />
	// 			Если у вас нет карты МИР - напишите нам в поддержку.
	// 		</>
	// 	);
	// }
	// setTransactionId(transaction);
	// setOrderId(order);
	// setResponse(responseCode);
	// setResponseText(description);
	// console.log({ responseCode, description });
	// setTimeout(() => {
	// 	setLoading(false);
	// }, 500);
	// }, []);

	// if (isLoading) {
	// 	return (
	// 		<>
	// 			<Helmet>
	// 				<title>Simple</title>
	// 				<meta name="referrer" content="origin" />
	// 			</Helmet>
	// 			<div className="container">
	// 				<div className="text">Идет загрузка транзакции</div>
	// 				<span className="sub-text"></span>
	// 				<div className="lds-dual-ring"></div>{" "}
	// 			</div>
	// 		</>
	// 	);
	// }

	// if (response !== "00") {
	// 	return (
	// 		<>
	// 			<Helmet>
	// 				<title>SimpleSMM | Что-то пошло не так</title>
	// 			</Helmet>
	// 			<div className="container">
	// 				<div className="text">Что-то пошло не так</div>
	// 				<span className="sub-text">{responseText}</span>
	// 				<span className="sub-text">
	// 					<b>{additionalText}</b>
	// 				</span>
	// 				<span className="sub-text">
	// 					{orderId} / {transactionId} / #{response}
	// 				</span>
	// 			</div>
	// 		</>
	// 	);
	// } else {
	return (
		<>
			<Helmet>
				<title>SimpleSMM | Успешная транзакция</title>
			</Helmet>
			<div className="container">
				<div className="text">✅</div>
				<div className="sub-text">Можно закрыть окно</div>
			</div>
		</>
	);
	// }
}
